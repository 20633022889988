import Error from "next/error"
import React from "react"

const Sitemap = ({ errorCode }) => {
  if (200 !== errorCode) {
    return <Error statusCode={ errorCode }/>
  }

  return null
}

export const getServerSideProps = async (context) => {
  const { res } = context
  const { file } = context.query
  const url = `${ process.env.SITEMAP_CLOUDFRONT_URL }${ process.env.SITEMAP_PATH }${ file }`
  const dataRes = await fetch(url)

  if (200 !== dataRes.status) {
    // Important: Amazon is returning 403 for non-existent URLs
    return {
      props: { errorCode: dataRes.status === 403 ? 404 : dataRes.status }
    }
  }

  const content = await dataRes.text()

  res.setHeader("Content-Type", "text/xml")
  res.write(content)
  res.end()

  return {
    props: {},
  }
}

export default Sitemap
